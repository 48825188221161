






















import { Component, Vue, Prop } from "vue-property-decorator";
import { paciente } from "@/shared/dtos/paciente-dto";
import { UtilsPaciente } from '@/utils/utils-paciente';
@Component({})
export default class PacienteFichaShow extends Vue {
  @Prop() paciente!: paciente;
  public ImgPaciente(paciente: paciente) {
    return  UtilsPaciente.getrutaImagen(paciente);
  }
}
